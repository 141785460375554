import angular from "angular";
import { clone, get, groupBy, isString } from "lodash";

export default function TeamsCtrl(
    ROLE_NAMES,
    $sbTeamDialogs,
    $mdDialog,
    EVENTS,
    $scope,
    $q,
    $log,
    $sbCurrentProject,
    $sbErrorPresenter,
    $sbTeam,
    $sbMembership,
    $state,
    $sbPermission,
    session,
    $sbTracking,
    $mdToast,
    PageFlags,
    Analytics
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    var project;
    const roles = [
        {
            ROLE_NAME: ROLE_NAMES.MANAGER,
        },
        {
            ROLE_NAME: ROLE_NAMES.CONTROLLER,
        },
        {
            ROLE_NAME: ROLE_NAMES.INSPECTOR,
        },
        {
            ROLE_NAME: ROLE_NAMES.REVIEWER,
        },
    ];

    var activeUser;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.ready = false;
    vm.listOfTeams = [];
    vm.hasAdminPermission = false;
    vm.hasManagerPermission = false;
    vm.PageFlags = PageFlags;

    vm.invitedMembers = [];

    // this controller can CUD teams and CUD team member
    vm.createTeam = createTeam;
    vm.editTeam = editTeam;
    vm.onMemberAdd = addMember;
    vm.onCommercialUserAdd = addCommercialUser;
    vm.onCommercialUserRemove = removeCommercialUser;
    vm.onMemberChange = editMember;
    vm.onInvitationOpen = onInvitationOpen;
    vm.onReplacementSelect = onReplacementSelect;
    vm.onReplacementActiveChange = onReplacementActiveChange;
    vm.isCurrentUser = isCurrentUser;
    vm.canUserBeEditedByManagers = canUserBeEditedByManagers;
    vm.canAssignInitialCommercialManager = canAssignInitialCommercialManager;
    vm.isAllowedToManageCommercialAccess = isAllowedToManageCommercialAccess;
    vm.countCommercialMembers = countCommercialMembers;

    activate();

    $scope.$on(EVENTS.GLOBAL_SEARCH_CHANGE, function (event, eventData) {
        vm.searchText = eventData.searchTerm;
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function activate() {
        project = $sbCurrentProject.get();
        activeUser = $sbMembership.currentUser();
        fetchAndSetTeamModel().then(() => {
            if (vm.hasAdminPermission) {
                roles.unshift({
                    ROLE_NAME: ROLE_NAMES.ADMIN,
                });
            }
        });
    }

    function fetchAndSetTeamModel() {
        return $q
            .all({
                teams: $sbTeam.getTeams(project.id),
                memberships: $sbTeam.getMemberships(project.id),
                invitations: $sbMembership.getMembershipInvitationsForProject(
                    project.id
                ),
            })
            .then(function ({ teams, memberships, invitations }) {
                teams.forEach((team) => {
                    team.members = memberships
                        .filter((membership) => membership.teamId === team.id)
                        .sort(orderByRoleAndInitials);
                });

                // First, group the commercial accesses by team ID
                const membershipsByTeam = groupBy(
                    memberships.flatMap((member) =>
                        member.commercialAccess.map((access) => ({
                            ...member,
                            role: "commercial_" + access.privilege,
                            commercialAccess: access,
                            teamId: access.onTeam,
                        }))
                    ),
                    "teamId"
                );

                // Assign commercial members to each team based on the grouped memberships
                teams.forEach((team) => {
                    team.commercialMembers = (
                        membershipsByTeam[team.id] || []
                    ).sort(orderByCommercialRoleAndInitials);
                });

                teams.sort((team1, team2) => {
                    if (team1.isProjectTeam) {
                        return -1;
                    } else if (team2.isProjectTeam) {
                        return 1;
                    }

                    return team1.name < team2.name ? -1 : 1;
                });

                vm.listOfTeams = teams;

                vm.invitedMembers = invitations;

                vm.ready = true;
                vm.hasAdminPermission = $sbPermission.hasAdminPermissions(
                    project.privileges
                );
                vm.hasTeamEditPermissions =
                    $sbPermission.hasTeamEditPermissions(project.privileges);

                vm.loadingUsers = false;
            })
            .catch($sbErrorPresenter.catch);
    }

    function createTeam() {
        return $sbTeamDialogs
            .openCreateTeamDialog()
            .then(function (team) {
                Analytics.trackConversion("team created");
                team.projectId = team.projectId || project.id;
                return team;
            })
            .then(_uploadTeam)
            .then(fetchAndSetTeamModel);
    }

    function editTeam(team) {
        $sbTeamDialogs
            .openEditTeamDialog(team)
            .then(function (editedTeam) {
                return _updateTeam(editedTeam);
            })
            .catch(function (team) {
                if (angular.isObject(team) && !team.hasMembers()) {
                    return _removeTeam(team);
                }
            });
    }

    function addMember(team) {
        return $sbTeamDialogs
            .openAddUserDialog(team, roles)
            .then(_addNewMembers)
            .then(fetchAndSetTeamModel)
            .catch(function () {})
            .catch($sbErrorPresenter.catch);
    }

    function addCommercialUser(team, isAddInitialCommercialManager = false) {
        const dialog = $mdDialog.editCostGroupMembership({
            locals: {
                allowSingleManagerOnly: isAddInitialCommercialManager,
            },
        });

        return $mdDialog.show(dialog).then(function (result) {
            const projectId = project.id;
            const teamId = team.id;
            const selectedManagers = result.selectedManagers;
            const selectedReviewers = result.selectedReviewers;

            if (selectedManagers.length > 0) {
                Analytics.trackEvent(
                    "sb_valuation_dashboard",
                    "commercial manager added",
                    "teams page"
                );
            }

            if (selectedReviewers.length > 0) {
                Analytics.trackEvent(
                    "sb_valuation_dashboard",
                    "commercial reviewer added",
                    "teams page"
                );
            }

            const commercialManagerRequests = selectedManagers.map(
                (manager) => {
                    return $sbMembership.addCommercialUser(
                        projectId,
                        manager.username,
                        teamId,
                        "manage"
                    );
                }
            );

            const commercialReviewerRequests = selectedReviewers.map(
                (reviewer) => {
                    return $sbMembership.addCommercialUser(
                        projectId,
                        reviewer.username,
                        teamId,
                        "read"
                    );
                }
            );

            Promise.all([
                ...commercialManagerRequests,
                ...commercialReviewerRequests,
            ])
                .then(() => {
                    fetchAndSetTeamModel();
                })
                .catch(_catchDialogCancel)
                .catch($sbErrorPresenter.catch);
        });
    }

    function removeCommercialUser(commercialUser, team) {
        if (commercialUser.role === "manage") {
            Analytics.trackEvent(
                "sb_valuation_dashboard",
                "commercial manager removed",
                "teams page"
            );
        }

        if (commercialUser.role === "read") {
            Analytics.trackEvent(
                "sb_valuation_dashboard",
                "commercial reviewer removed",
                "teams page"
            );
        }

        const projectId = project.id;
        const userId = commercialUser.username;
        const teamId = team.id;

        return $sbMembership
            .deleteCommercialUser(projectId, userId, teamId)
            .then(() => {
                fetchAndSetTeamModel();
            })
            .catch(_catchDialogCancel)
            .catch($sbErrorPresenter.catch);
    }

    function editMember(user) {
        const hasAreaManagerPrivilege = user.hasAreaManagerPrivilege;

        $sbTeamDialogs
            .openEditUserDialog(user, vm.listOfTeams, roles)
            .then(function (result) {
                const beforeUser = result.before;
                const afterUser = result.after;

                beforeUser.hasAreaManagerPrivilege = hasAreaManagerPrivilege;

                return handleEditUserChange(beforeUser, afterUser);
            })
            .then(fetchAndSetTeamModel)
            .catch(_catchDialogCancel)
            .catch($sbErrorPresenter.catch);
    }

    function onInvitationOpen(invitation) {
        $sbTeamDialogs
            .openPendingInvitationDialog(invitation, vm.listOfTeams, roles)
            .then(({ after }) => {
                // Create a new invitation based on the given after model
                //  -> this will supersede any existing one and resend the invite email
                return $sbMembership.inviteUser(
                    after.contact.email,
                    after.membership.team.id,
                    after.membership.role.ROLE_NAME.toLowerCase(),
                    project.id
                );
            })
            .then(fetchAndSetTeamModel)
            .catch(_catchDialogCancel)
            .catch($sbErrorPresenter.catch);
    }

    function handleEditUserChange(oldUser, newUser) {
        const isActiveUser = activeUser.userName === oldUser.userId;
        const hasBeenAdmin = oldUser.roleName === ROLE_NAMES.ADMIN;
        const hasBeenManager = oldUser.roleName === ROLE_NAMES.MANAGER;

        if (!newUser) {
            if (isActiveUser && hasBeenAdmin) {
                return _confirmDeleteYourself(oldUser);
            } else {
                return _removeMember({
                    team: {
                        id: oldUser.teamId,
                        projectId: project.id,
                    },
                    user: {
                        id: oldUser.userId,
                    },
                });
            }
        } else {
            const isRoleChanged = oldUser.roleName !== newUser.roleName;

            if (isActiveUser && hasBeenAdmin && isRoleChanged) {
                return _confirmLossOfAdminRights(oldUser, newUser);
            }

            if (isActiveUser && hasBeenManager && isRoleChanged) {
                return _confirmLossOfProjectManager(oldUser, newUser);
            }

            if (
                oldUser.hasAreaManagerPrivilege &&
                _isChangingTeams(oldUser, newUser)
            ) {
                return _confirmLossOfConstructionManager(oldUser, newUser);
            }

            return _changeMember(oldUser, newUser);
        }
    }

    function canAssignInitialCommercialManager(commercialMembers) {
        const activeUserHasPrivileges = commercialMembers.some(
            (member) =>
                member.username === activeUser.userName &&
                member.commercialAccess.privilege === "define_manager"
        );
        const hasCommercialManager = commercialMembers.some(
            (member) => member.commercialAccess.privilege === "manage"
        );

        return activeUserHasPrivileges && !hasCommercialManager;
    }

    function isAllowedToManageCommercialAccess(commercialMembers) {
        return commercialMembers.some(
            (member) =>
                member.username === activeUser.userName &&
                member.commercialAccess.privilege === "manage"
        );
    }

    function countCommercialMembers(commercialMembers) {
        return commercialMembers.filter(
            (member) => member.commercialAccess.privilege !== "define_manager"
        ).length;
    }

    function _confirmDeleteYourself(oldUser) {
        return $sbTeamDialogs
            .openConfirmDeleteYourselfDialog()
            .then(function (isConfirmed) {
                if (isConfirmed) {
                    return _removeMember({
                        team: {
                            id: oldUser.teamId,
                            projectId: project.id,
                        },
                        user: {
                            id: oldUser.userId,
                        },
                    }).then(function broadcastPermissionChanged() {
                        $sbTracking.team.onMemberRemovedItselfAsAdmin();

                        return $state.go("sablono.projectSelection");
                    });
                }
            });
    }

    function _confirmLossOfAdminRights(oldUser, newUser) {
        return $sbTeamDialogs
            .openConfirmLossOfAdminRights()
            .then(function (isConfirmed) {
                if (isConfirmed) {
                    if (
                        oldUser.hasAreaManagerPrivilege &&
                        _isChangingTeams(oldUser, newUser)
                    ) {
                        return _confirmLossOfConstructionManager(
                            oldUser,
                            newUser
                        );
                    } else {
                        return _changeMember(oldUser, newUser);
                    }
                }
            })
            .then(function broadcastPermissionChanged() {
                $sbMembership.clearCache();
                return $sbMembership
                    .get(project.id, $sbMembership.currentUser().userName)
                    .then(function (membership) {
                        project.privileges = $sbPermission.bitMaskByRoleName(
                            membership.role.name
                        );
                    })
                    .catch(function (e) {
                        $log.error(e);
                        project.privileges = 2;
                    })
                    .then(activate);
            });
    }

    function _isChangingTeams(oldUser, newUser) {
        return oldUser.teamId !== newUser.teamId;
    }

    function _confirmLossOfConstructionManager(oldUser, newUser) {
        return $sbTeamDialogs
            .openConfirmLossOfConstructionManager()
            .then(function (isConfirmed) {
                if (isConfirmed) {
                    return _changeMember(oldUser, newUser);
                }
            });
    }

    function _confirmLossOfProjectManager(oldUser, newUser) {
        return $sbTeamDialogs
            .openConfirmLossOfProjectManagerRights()
            .then(function (isConfirmed) {
                if (isConfirmed) {
                    return _changeMember(oldUser, newUser);
                }
            });
    }

    function _updateTeam(team) {
        return $sbTeam
            .updateTeam(team)
            .then(fetchAndSetTeamModel)
            .then(function () {
                _trackTeamManagementEvent("Updated");
            })
            .catch($sbErrorPresenter.catch);
    }

    function _removeTeam(team) {
        return $sbTeam
            .deleteTeam(team)
            .then(fetchAndSetTeamModel)
            .then(function () {
                _trackTeamManagementEvent("Removed");
            })
            .catch($sbErrorPresenter.catch);
    }

    function _uploadTeam(newTeam) {
        return $sbTeam
            .createTeam(newTeam)
            .then(function () {
                _trackTeamManagementEvent("Created");
            })
            .catch($sbErrorPresenter.catch);
    }

    function _addNewMembers(memberships) {
        vm.loadingUsers = true;
        return memberships.reduce(function (promise, newUser) {
            return promise.then(function () {
                return $sbMembership
                    .inviteUser(
                        newUser.userEmail,
                        newUser.teamId,
                        newUser.roleName.toLowerCase(),
                        project.id
                    )
                    .then(function () {
                        _trackMemberManagementEvent("Member added");
                    })
                    .catch(function (error) {
                        const isAlreadyInvitedError =
                            "ERROR_MULTIPLE_INVITATIONS_PER_PROJECT";

                        if (error?.message === isAlreadyInvitedError) {
                            _showToast(isAlreadyInvitedError);
                        } else {
                            _showToast("ERROR_REQUEST_MESSAGE");
                        }
                    });
            });
        }, $q.resolve());
    }

    function _removeMember(membership) {
        return $sbTeam.deleteUser(membership).then(function () {
            _trackMemberManagementEvent("Member removed");
        });
    }

    function _trackTeamManagementEvent(event) {
        $sbTracking.team.onTeamManagement(event);
    }

    function _trackMemberManagementEvent(event) {
        $sbTracking.team.onMemberManagement(event);
    }

    function _trackLeaveDelegationEvent(event) {
        $sbTracking.constructionManager.onLeaveDelegation(event);
    }

    function _changeMember(before, after) {
        const editingMyself = session.username === before.userId;

        if (editingMyself) {
            $sbMembership.clearCache();
        }

        return $sbTeam.changeMembership(
            {
                team: {
                    id: before.teamId,
                    projectId: project.id,
                },
                user: {
                    id: before.userId,
                },
            },
            {
                role: after.roleName.toLowerCase(),
                teamId: after.teamId,
                substitute: after.substitute,
            }
        );
    }

    function _catchDialogCancel(e) {
        vm.loadingUsers = false;
        if (angular.isUndefined(e)) {
            return e;
        } else {
            throw e;
        }
    }

    function _showToast(message) {
        return $mdToast.show(
            $mdToast
                .simple()
                .content(message)
                .hideDelay(3000)
                .position("top right")
        );
    }

    function orderByRoleAndInitials(second, first) {
        if (first.role === second.role) {
            if (isString(first.initials) && isString(second.initials)) {
                return first.initials.localeCompare(second.initials) * -1;
            } else {
                return 0;
            }
        } else {
            return (
                $sbPermission.bitMaskByRoleName(first.role) -
                $sbPermission.bitMaskByRoleName(second.role)
            );
        }
    }

    function orderByCommercialRoleAndInitials(second, first) {
        const privilegeOrder = {
            commercial_define_manager: 0, // should be unused (hidden in UI)
            commercial_read: 1,
            commercial_manage: 2,
        };
        if (first.role === second.role) {
            if (isString(first.initials) && isString(second.initials)) {
                return first.initials.localeCompare(second.initials) * -1;
            } else {
                return 0;
            }
        } else {
            return privilegeOrder[first.role] - privilegeOrder[second.role];
        }
    }

    function onReplacementSelect(areaManager, selectedReplacement) {
        const before = clone(areaManager);
        areaManager.selectedReplacement = selectedReplacement;

        return _changeReplacement(
            areaManager,
            selectedReplacement,
            before
        ).then((response) => {
            const isSet = get(response, "substitute.user.id");
            _trackLeaveDelegationEvent(
                isSet ? "Replacement defined" : "Replacement deleted"
            );
        });
    }

    function onReplacementActiveChange(areaManager, selectedReplacement) {
        const before = clone(areaManager);
        before.isReplacementActive = !areaManager.isReplacementActive;

        return _changeReplacement(
            areaManager,
            selectedReplacement,
            before
        ).then((response) => {
            const isActive = get(response, "substitute.is_active");
            _trackLeaveDelegationEvent(
                isActive ? "Replacement activated" : "Replacement deactivated"
            );
        });
    }

    function _changeReplacement(areaManager, selectedReplacement, before) {
        return _changeMember(
            {
                userId: areaManager.username,
                teamId: areaManager.teamId,
            },
            {
                roleName: areaManager.role,
                substitute: {
                    user_id: get(
                        selectedReplacement,
                        "username",
                        "sb::substitute.not-set"
                    ),
                    is_active: get(areaManager, "isReplacementActive", false),
                },
            }
        ).catch((error) => handleReplacementError(error, areaManager, before));
    }

    function handleReplacementError(error, areaManager, before) {
        if (error.message === "ERROR_CHAIN_LEAVE_AREA_MANAGER_UNSUPPORTED") {
            $sbTracking.constructionManager.onKnownIssue(
                "Tried to replace CM who replaces someone else"
            );
        }

        // reset UI model to before state
        areaManager.isReplacementActive = before.isReplacementActive;
        areaManager.selectedReplacement = before.selectedReplacement;

        return $sbErrorPresenter.catch(error);
    }

    function isCurrentUser(user) {
        return user.username === activeUser.userName;
    }

    function canUserBeEditedByManagers(user) {
        if (user.role === "ADMIN") {
            return false;
        }

        return true;
    }
}
