import html from "./legend.tmpl.html";

export default {
    templateUrl: html,
    controllerAs: "legend",
    bindings: {
        entries: "<",
        onToggle: "&",
    },
    controller: function LegendCtrl($window) {
        "ngInject";
        /////////////////////
        //
        //      Direct variables
        //
        /////////////////////

        var vm = this;

        vm.open = true;
        vm.IPAD_PRO_SMALL_SIZE_IN_PIXEL = 1024;
        /////////////////////
        //
        //      View model properties
        //
        /////////////////////

        vm.toggle = toggle;

        /////////////////////
        //
        //      Lifecycle Hooks
        //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
        //
        /////////////////////

        vm.$onInit = function () {
            if ($window.innerWidth < vm.IPAD_PRO_SMALL_SIZE_IN_PIXEL) {
                vm.open = false;
            }
        };

        vm.$onChanges = function (changes) {
            if (vm.open) {
                // if already open nothing to do here
                return;
            }

            const before = changes.entries.previousValue;
            const after = changes.entries.currentValue;
            if (
                Array.isArray(before) &&
                Array.isArray(after) &&
                before.length !== after.length
            ) {
                // we only show the legend (again) if the legend entries are different
                vm.open = true;
                vm.onToggle({
                    isOpen: vm.open,
                });
            }
        };

        /////////////////////
        //
        //      IMPL
        //
        /////////////////////

        function toggle() {
            vm.open = !vm.open;
            vm.onToggle({
                isOpen: vm.open,
            });
        }
    },
};
