import _ from "lodash";
import moment from "moment";
import ODataFilterFactory from "./odata_filter_factory.class";
import ViewFilter from "./odata_view_filter.class";

export default function (
    $log,
    MAX_URL_LENGTH_INTERNET_EXPLORER,
    $sbStructure,
    $sbProject,
    $sbTeam,
    $filter
) {
    "ngInject";
    function UrlLimitExceededException(message) {
        this.message = message;
        this.name = "UrlLimitExceededException";
    }

    /**
     * Option class that uses the given tree structure to create a nested OData expression that
     * says me or one of my child nodes.
     *
     * Is used and conform to ViewFilter options interface.
     *
     * @param {String} id       - Element identifier
     * @param {String} name     - Element visual human readable representation
     * @param {String} code     - Element visual human short readable representation
     * @param {Object} structureSpanIndex
     * @param {Number} structureSpanIndex.left
     * @param {Number} structureSpanIndex.right
     * @param {String} oDataKey - oData column name
     * @constructor
     */
    function StructureOption(id, name, code, structureSpanIndex, oDataKey) {
        this.label = name;
        this.name = id;
        this.code = code;
        this.oDataKey = oDataKey;

        this.structureSpanIndex = structureSpanIndex;
    }

    StructureOption.prototype.odata = function (factory) {
        factory.between(
            this.oDataKey,
            this.structureSpanIndex.left,
            this.structureSpanIndex.right
        );
        return factory;
    };

    /////////
    //  API
    ////////

    return {
        // helper to work with arrays of ViewFilter
        combinedOData: combinedOData,
        combinedForReport: combinedForReport,
        combinedODataWithOrFrom: combinedODataWithOrFrom,
        combinedODataWithOperatorsFrom: combinedODataWithOperatorsFrom,
        projectFilter: projectFilter,
        teamFilter: teamFilter,
        structureFilter: structureFilter,
        setViewFilterValuesFrom: setViewFilterValuesFrom,
        generateFilterExpress: generateFilterExpress,
    };

    /////////
    //  IMPL
    ////////

    /**
     * Create one OData Filter Factory to represent all view filter conditions combined with AND!
     *
     * @param {Array<ViewFilter>} viewFilters
     * @returns {ODataFilterFactory}
     */
    function combinedOData(viewFilters) {
        var combinedFactory = new ODataFilterFactory();

        if (!_.isArray(viewFilters)) {
            $log.debug(
                ">VF< -> combine odata conditions aboard, due to view filters are not an array but:",
                viewFilters
            );
            return combinedFactory;
        }

        if (_.isArray(viewFilters) && viewFilters.length === 0) {
            $log.debug(
                ">VF< -> combine odata conditions aboard, due to view filters are an empty array"
            );
            return combinedFactory;
        }

        _combineFiltersToODataExpressionWithLogicalOperators(
            combinedFactory,
            viewFilters,
            "AND"
        );

        return combinedFactory;
    }

    /**
     * Helper function to append a logical operator by name to an ODataFilterFactory.
     *
     * @param {ODataFilterFactory} oDataFactory
     * @param {String} logicalOperator
     * @private
     */
    function _appendLogicalOperator(oDataFactory, logicalOperator) {
        var fn = oDataFactory[logicalOperator.toLowerCase()];
        if (_.isFunction(fn)) {
            fn.call(oDataFactory);
        }
    }

    /**
     * Create one OData Filter Factory to represent all view filter conditions.
     *
     * The conditions are combined with logical operators, which can be one string or
     * an array of strings representing logical operators (AND | OR).
     *
     * If one string is given all conditions are combined by that operator.
     * If an array of operator is given the conditions and operators are merged.
     * The number of operators needs to be at least one less than the number of conditions.
     *
     * @example
     * var conditions = ["'A eq 'A'", "B eq 'B'", "B eq 'B'"] // items are result of viewFilter.applyOdata
     * var operators = ["AND", "OR"];
     *
     * result = ["'A eq 'A'", "AND", "B eq 'B'", "OR", "B eq 'B'"] // filters array of oDataFactory
     *
     *
     * @param {Array<ViewFilter>} viewFilters
     * @param {Array<String>|String} logicalOperators - Currently only AND and OR are supported.
     *
     * @returns {ODataFilterFactory}
     */
    function combinedODataWithOperatorsFrom(viewFilters, logicalOperators) {
        var combinedFactory = new ODataFilterFactory();

        if (!_.isArray(viewFilters)) {
            $log.debug(
                ">VF< -> combine odata conditions aboard, due to view filters are not an array but:",
                viewFilters
            );
            return combinedFactory;
        }

        if (_.isArray(viewFilters) && viewFilters.length === 0) {
            $log.debug(
                ">VF< -> combine odata conditions aboard, due to view filters are an empty array"
            );
            return combinedFactory;
        }

        _combineFiltersToODataExpressionWithLogicalOperators(
            combinedFactory,
            viewFilters,
            logicalOperators
        );

        return combinedFactory;
    }

    /**
     * Generate string expression from list OData filters as  after filtering out not displayable filters
     * Convert oderBy condition to ODataFilter and append it to the filters expression
     * Return the expression and the displayable subset of the given filters
     * @param filters
     * @param columns
     * @param orderBy
     * @returns {{oDataFilterExpr, activeFilterWithoutHiddenFields: ODataFilterFactory}}
     */
    function generateFilterExpress(filters, columns, orderBy) {
        var oDataFilterExpr = combinedOData(filters).get();

        //here we could use the current sorting to give this information to the printing function
        var activeFilterWithoutHiddenFields = filters.filter(function (vf) {
            return vf.isDisplayable;
        });

        var oDataOrderBy = _toOdataOrderByString({
            direction: orderBy.direction,
            criteria: columns[orderBy.criteria].oDataKey,
        });

        // generate data for new API query
        const query = {
            filter: oDataFilterExpr,
            orderby: oDataOrderBy.substring(1),
        };

        if (oDataOrderBy) {
            oDataFilterExpr += "&" + oDataOrderBy;
        }

        return {
            query,
            oDataFilterExpr: oDataFilterExpr,
            activeFilterWithoutHiddenFields: combinedForReport(
                activeFilterWithoutHiddenFields
            ),
        };
    }

    function _toOdataOrderByString(orderBy) {
        if (!orderBy) {
            return undefined;
        }

        var queryString = orderBy.criteria;
        if (queryString && queryString.length !== 0 && orderBy.direction < 0) {
            queryString += " desc";
        }

        return "$orderby=" + queryString;
    }

    /**
     * @see combinedODataWithOperatorsFrom
     *
     * @param combinedFactory
     * @param viewFilters
     * @param logicalOperators
     *
     * @returns {ODataFilterFactory}
     * @private
     */
    function _combineFiltersToODataExpressionWithLogicalOperators(
        combinedFactory,
        viewFilters,
        logicalOperators
    ) {
        var viewFiltersWithValues = viewFilters.filter(function (vf) {
            return vf.hasValue();
        });

        var isSingleOperator =
            _.isString(logicalOperators) &&
            ["AND", "OR"].indexOf(logicalOperators) !== -1;
        if (isSingleOperator) {
            viewFiltersWithValues.forEach(function (vf, index) {
                if (index > 0) {
                    _appendLogicalOperator(combinedFactory, logicalOperators);
                }
                vf.applyOdata(combinedFactory);
            });
        }

        var isArrayOfOperators = _.isArray(logicalOperators);
        if (isArrayOfOperators) {
            var isMergable = viewFilters.length - 1 <= logicalOperators.length;
            if (isMergable) {
                viewFiltersWithValues.forEach(function (vf, index) {
                    if (index > 0) {
                        _appendLogicalOperator(
                            combinedFactory,
                            logicalOperators[index - 1]
                        );
                    }
                    vf.applyOdata(combinedFactory);
                });
            } else {
                $log.debug(
                    ">VF< -> merge odata conditions aboard, due to number of view filters and logical operators do not match."
                );
            }
        }

        if (isExceedingMaxUrlLength(combinedFactory)) {
            throw new UrlLimitExceededException(
                "The resulting filter query can not be handled by browsers"
            );
        }

        return combinedFactory;
    }

    /**
     * Create one OData Filter Factory to represent all view filter conditions combined with OR!
     *
     * @param {Array<ViewFilter>} viewFilters
     * @returns {ODataFilterFactory}
     */
    function combinedODataWithOrFrom(viewFilters) {
        var combinedFactory = new ODataFilterFactory();

        if (!_.isArray(viewFilters)) {
            $log.debug(
                ">VF< -> combine odata conditions aboard, due to view filters are not an array but:",
                viewFilters
            );
            return combinedFactory;
        }

        if (_.isArray(viewFilters) && viewFilters.length === 0) {
            $log.debug(
                ">VF< -> combine odata conditions aboard, due to view filters are an empty array"
            );
            return combinedFactory;
        }

        _combineFiltersToODataExpressionWithLogicalOperators(
            combinedFactory,
            viewFilters,
            "OR"
        );

        return combinedFactory;
    }

    /**
     * Create an array of objects with key and value while key is the specified KEY Label and value is
     * the value text. Both should be human readable by default.
     *
     *  LIKE:
     *      Meldungen: Restleistungen und Hindernisse
     *
     *      or
     *
     *      Schedule: on time
     *
     *
     * @param {Array<ViewFilter>} viewFilters
     * @returns {Array<Object>} - List of key - value pairs that are usable for human readable filter description.
     */
    function combinedForReport(viewFilters) {
        if (!_.isArray(viewFilters)) {
            $log.debug(
                ">VF< -> combine report aboard, due to view filters are not an array but:",
                viewFilters
            );
            return [];
        }

        return viewFilters.map(function (viewFilter) {
            var reportReady = viewFilter.getValuesTranslatableForReports();
            var reportValues = reportReady.value.map(function (value) {
                return $filter("translate")(value);
            });
            return {
                key: _.startCase($filter("translate")(reportReady.key)),
                value: reportValues.join(", "),
            };
        });
    }

    function eqFilter(keyLabel, key, oDataProperty) {
        return new ViewFilter(keyLabel, key)
            .setODataHandler(function (factory, value) {
                return factory.eq(oDataProperty, value);
            })
            .enableNullCondition(oDataProperty);
    }

    function projectFilter() {
        return eqFilter("", "projectId", "PROJECT_ID").setDisplayable(false);
    }

    /**
     * Configures all view filters to reflect the active URL params.
     *
     * @param {Object} params - @see $stateParams like map.
     * @param {Array.<Object>} filters - An array with view filter objects. Representing current filter state.
     * @private
     */
    function setViewFilterValuesFrom(params, filters) {
        // url to view filter initial values

        var nameToFilterMap = filters.reduce(function (map, filter) {
            map[filter.key] = filter;
            return map;
        }, {});

        // go over URL params and configure view filters
        Object.keys(params).forEach(function (key) {
            var value = params[key];
            var filter = nameToFilterMap[key];

            if (filter) {
                filter.setValue(value);
            } else {
                $log.debug(key + " not found in params.");
            }
        });
    }

    /**
     * create a view filter for teams
     *
     * @param {String} projectId      - to find the teams of this project
     * @param {String} filterKeyLabel - human readable name of the filter
     * @param {String} filterKey      - name of the filter
     * @param {String} oDataKey       - oData column name
     * @returns {*}
     */
    function teamFilter(projectId, filterKeyLabel, filterKey, oDataKey) {
        return $sbTeam.getTeams(projectId).then(function (teams) {
            // create select options from the teams
            var teamSelectOptions = teams.map(function (team) {
                return {
                    label: team.getDisplayName(),
                    odata: function (factory) {
                        return factory.eq(oDataKey, team.id);
                    },
                    name: team.id.toString(), // name has to be a string while team id is always a number
                };
            });

            // add the "free for all" team
            teamSelectOptions.unshift({
                label: $sbTeam.UNRESTRICTED_I18N_KEY,
                odata: function (factory) {
                    return factory.eq(oDataKey, null);
                },
                name: "all",
            });

            var vf = new ViewFilter(filterKeyLabel, filterKey).setOptions(
                teamSelectOptions
            );

            vf.type = ViewFilter.TYPE.SELECT;
            return vf;
        });
    }

    /**
     *  creates a new structure filter
     * @param {String} filterKeyLabel - human readable name of the filter
     * @param {String} filterKey      - name of the filter
     * @param {String} oDataKey       - oData column name
     */

    function structureFilter(filterKeyLabel, filterKey, oDataKey) {
        return _fetchStructureMapByProject()
            .then(_flattenStructure)
            .then(function (structureNodes) {
                var naturalSort = $filter("naturalSort");
                var sortedNodes = naturalSort(structureNodes, "CODE");

                var vf = new ViewFilter(filterKeyLabel, filterKey)
                    .setOptions(
                        sortedNodes.map(function (sNode) {
                            return new StructureOption(
                                sNode.ID,
                                sNode.PARENT_PATH.join(" / "),
                                sNode.CODE,
                                {
                                    left: sNode.LEFT_TREE_SPAN,
                                    right: sNode.RIGHT_TREE_SPAN,
                                },
                                oDataKey
                            );
                        })
                    )
                    .enableNullCondition(oDataKey);
                vf.type = ViewFilter.TYPE.STRUCTURE;
                vf.isInReducedFilters = true;
                return vf;
            });
    }

    ////////
    //
    //  LOGIC to make the structure filter work.
    //
    ////////

    function _fetchStructureMapByProject() {
        return $sbStructure.tree($sbProject.getCurrentProjectId());
    }

    function _flattenStructure(structure) {
        return _flatten(structure, "CHILDREN");
    }

    function _flatten(roots, key) {
        var unprocessed = roots.concat([]);
        var processed = [];
        while (unprocessed.length > 0) {
            var next = unprocessed.pop();

            if (next) {
                processed.push(next);
                unprocessed.push.apply(unprocessed, [].concat(next[key]));
            }
        }
        return processed;
    }

    function isExceedingMaxUrlLength(filterFactory) {
        return (
            filterFactory.getLengthOfResultingFilterExpression() >
            MAX_URL_LENGTH_INTERNET_EXPLORER
        );
    }
}
